import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'index',
		component: () => import('../views/index.vue')
	}
	// ,{
	// 	path: '/home',
	// 	name: 'home',
	// 	component: () => import('../views/home.vue')
	// },
	// {
	// 	path: '/jobs',
	// 	name: 'jobs',
	// 	component: () => import('../views/jobs.vue')
	// },
	// {
	// 	path: '/news',
	// 	name: 'news',
	// 	component: () => import('../views/news.vue')
	// },
	// {
	// 	path: '/information',
	// 	name: 'information',
	// 	component: () => import('../views/information.vue')
	// }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router