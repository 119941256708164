<template>
	<div class="uc-home-1">
		<swiper :options="swiperOption" ref="swiper">
			<swiper-slide v-for="(item,index) in 3" :key="index">
				<router-link to="">
					<img src="@/assets/images/index/bgc.png" class="d-none d-sm-block" alt="" />
					<img src="@/assets/images/index/bgc.png" class="d-block d-sm-none" alt="" />
					<!-- <img src="@/assets/images/home-banner.png" class="d-block d-sm-none" alt=""/> -->
					<!-- <img src="@/assets/images/home-banner-phone.png" class="d-block d-sm-none" alt=""/> -->
				</router-link>
			</swiper-slide>
			<!-- <div class="swiper-pagination" slot="pagination"></div> -->
		</swiper>
	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	export default {
		components: {
			Swiper,
			SwiperSlide
		},
		data() {
			return {
				swiperOption: {
					loop: true,
					observeParents: true,
					observer: true,
					pagination: {
						el: '.uc-home-1 .swiper-container .swiper-pagination',
						clickable: true,
					},
					autoplay: {
						delay: 2500,
						disableOnInteraction: false,
					},
				},
			};
		},
		mounted() {}
	};
</script>

<style>
	.swiper-pagination-bullet {
		background-color: #fff;
		opacity: .8;
	}

	.swiper-pagination-bullet-active {
		background-color: #e60012;
		width: 2em;
		border-radius: 1.15em;
		opacity: 1;
	}
</style>