<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    mounted() {
      window.onresize = () => {location.reload();}
    },
  }
</script>
