<template>
	<div>
		<!-- pc版 -->
		<header id="nav_box" class="nav_box container-fluid header-pc d-none d-md-block">
			<div class="container">
				<div class="left demo">
					<router-link to="" class="logo"><img src="@/assets/images/index/logo.png" class="logo_img"
							alt=""></router-link>
					<ul class="list menu">
						<li class="hasSubMenu" :class="current==0?'on':''">
							<router-link to="">首页</router-link>
						</li>
						<li class="hasSubMenu" :class="current==1?'on':''">
							<router-link to="">关于共达</router-link>
							<div class="subMenu" id="subNav">
								<div class="con">
									<router-link to="">资讯</router-link>
									<router-link to="">资讯2</router-link>
									<router-link to="">资讯</router-link>
									<router-link to="">资讯</router-link>
									<router-link to="">资讯</router-link>
								</div>
							</div>
						</li>
						<li :class="current==2?'on':''">
							<router-link to="">新闻动态</router-link>
							<div class="subMenu" id="subNav1">
								<div class="con">
									<router-link to="">企业简介</router-link>
									<router-link to="">业态信息</router-link>
									<router-link to="">人才招募</router-link>
									<router-link to="">招商</router-link>
									<router-link to="">关于我们</router-link>
								</div>
							</div>
						</li>
						<li :class="current==3?'on':''"><router-link to="">产品与方案</router-link></li>
						<li :class="current==4?'on':''"><router-link to="">投资者关系</router-link></li>
						<li :class="current==5?'on':''"><router-link to="">加入共达</router-link></li>
						<li :class="current==6?'on':''"><router-link to="">联系我们</router-link></li>
					</ul>
					<div class="ali">
						<img src="@/assets/images/index/search.png" class="search_img" alt="" />
						<div class="line"></div>
						<div class="line_text">EN</div>
					</div>
					<router-link to="">
						<!-- <div class="gray_yuan"></div> -->
						<img src="@/assets/images/index/right.png" class="gray_yuan" alt="">
					</router-link>
				</div>
			</div>
		</header>
		<!-- 手机版 -->
		<!-- <header class="header-phone d-md-none">
			<router-link to="" class="logo"><img src="@/assets/images/logo.png" alt=""></router-link>
			<div to="" class="btn-menu" :class="showMenu?'on':''" @click="showMenu = !showMenu"></div>
		</header> -->
		<div class="alert-menu" v-if="showMenu">
			<div class="over-close" @click="showMenu = false"></div>
			<div class="box">
				<ul class="list menu">
					<li class="hasSubMenu" :class="current==0?'on':''">
						<div class="btn-more" @click="showMore1 = !showMore1">首页</div>
						<div class="subMenu" v-if="showMore1">
							<div class="con">
								<router-link to="">企业简介</router-link>
								<router-link to="">业态信息</router-link>
								<router-link to="">人才招募</router-link>
								<router-link to="">招商</router-link>
								<router-link to="">关于我们</router-link>
							</div>
						</div>
					</li>
					<li class="hasSubMenu" :class="current==1?'on':''">
						<div class="btn-more" @click="showMore2 = !showMore2">资讯</div>
						<div class="subMenu" v-if="showMore2">
							<div class="con">
								<router-link to="">资讯</router-link>
								<router-link to="">资讯2</router-link>
								<router-link to="">资讯</router-link>
								<router-link to="">资讯</router-link>
								<router-link to="">资讯</router-link>
							</div>
						</div>
					</li>
					<li :class="current==2?'on':''"><router-link to="">人才</router-link></li>
					<li :class="current==3?'on':''"><router-link to="">招商合作</router-link></li>
					<li :class="current==4?'on':''"><router-link to="">联系我们</router-link></li>
					<li class="hasSubMenu">
						<div class="btn-more" @click="showMore3 = !showMore3" o="">EN</div>
						<div class="subMenu" v-if="showMore3">
							<div class="con">
								<router-link to="">EN</router-link>
								<router-link to="">EN2</router-link>
								<router-link to="">EN</router-link>
								<router-link to="">EN</router-link>
								<router-link to="">EN</router-link>
							</div>
						</div>
					</li>
					<li>
						<router-link to="" class="btn">
							0631-3855555 <img src="@/assets/images/call-white.png" class="uc-icon16" alt="" />
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: '',
		components: {},
		props: {
			current: {
				type: Number,
				default: null,
			},
		},
		data() {
			return {
				showMenu: false,
				showMore1: false,
				showMore2: false,
				showMore3: false,
			}
		},
		mounted() {
			var box = document.getElementById('nav_box');
			var boxs = document.getElementById('subNav');
			var boxs1 = document.getElementById('subNav1');

			window.onscroll = function() {
				var scrolledDistance = window.pageYOffset || document.documentElement.scrollTop;
				console.log(scrolledDistance);
				console.log(box);
				console.log(boxs);
				if (scrolledDistance > 600) {
					box.classList = 'new_boxs container-fluid header-pc d-none d-md-block';
					boxs.classList = 'subMenus';
					boxs1.classList = 'subMenus';
				} else {
					box.classList = 'nav_box container-fluid header-pc d-none d-md-block';
					boxs.classList = 'subMenu';
					boxs1.classList = 'subMenu';
				}
			};
		},
		methods: {},
	}
</script>

<style>
	.line_text {
		font-size: 16px;
		color: #004D8B;
		margin-right: 90px;
	}

	.line {
		width: 1px;
		height: 20px;
		background-color: skyblue;
		margin: 0 14px;
	}

	.search_img {
		width: 18px;
		height: 18px;
	}

	.demo {
		display: flex;
		justify-content: space-between;
	}

	.gray_yuan {
		width: 55px;
		height: 55px;
		border-radius: 50%;
		background-color: #737373;
	}

	.header-pc .logo_img {
		width: 264px;
	}

	.nav_box {
		width: 1822px;
		height: 80px;
		border-radius: 20px 20px 0 0;
		background-color: #bad0e4;
		position: fixed;
		top: 60px;
		left: 49px;
	}

	.new_boxs {
		width: 100%;
		height: 80px;
		position: fixed;
		top: 0;
		left: 0;
		background-color: #bad0e4;
		border-radius: 0;
	}
</style>