<template>
	<div>
		<footer class="container-fluid bg_blue">
			<!-- <div class="ft1">
				<div class="container">
					<div class="row">
						<div class="col-24 col-sm-10 col-md-8">
							<router-link to="" class="logo">
								<img src="@/assets/images/logo-white.png" alt="" />
							</router-link>
						</div>
						<div class="col-24 col-sm-14 col-md-16">
							<div class="call">
								<img src="@/assets/images/call-white.png" class="uc-icon32" alt="" />
								官方服务热线 &nbsp;0631-3855555
							</div>
						</div>
					</div>
				</div>
			</div> -->
			<div class="ft2">
				<div class="containerss">
					<ul class="row list">
						<li class="col-18 col-sm-4 col-xl-6 offset-xl-2">
							<div class="contact">
								<div class="item">
									<img src="@/assets/images/index/logos.png" class="logo_img" alt="">
								</div>
								<div class="item">
									<div class="r">电话：86-536-2283666-838</div>
								</div>
								<div class="item">
									<div class="r">传真：86-536-2283006</div>
								</div>
								<div class="item">
									<div class="r">邮箱：sales@gettopaccoustic.com</div>
								</div>
								<div class="item">
									<div class="r">地址：山东省潍坊市坊子区渤海路68号</div>
								</div>
							</div>
						</li>

						<li class="col-6 col-sm-4 col-xl-3">
							<h3>首页</h3>
							<div class="con">
								<router-link to="">企业简介</router-link>
								<router-link to="">企业荣誉</router-link>
							</div>
						</li>
						<li class="col-6 col-sm-4 col-xl-4">
							<h3>资讯</h3>
							<div class="con">
								<router-link to="">九龙城事业部</router-link>
								<router-link to="">酒店事业部</router-link>
								<router-link to="">咖啡事业部</router-link>
								<router-link to="">地产事业部</router-link>
								<router-link to="">信息披露</router-link>
							</div>
						</li>
						<li class="col-6 col-sm-4 col-xl-3">
							<h3>人才</h3>
							<div class="con">
								<router-link to="">人才招募</router-link>
								<router-link to="">职业发展</router-link>
							</div>
						</li>
						<li class="col-6 col-sm-4 col-xl-3">
							<h3>招商合作</h3>
							<div class="con">
								<router-link to="">招商</router-link>
							</div>
						</li>
						<li class="col-6 col-sm-4 col-xl-3">
							<h3>联系我们</h3>
							<div class="con">
								<router-link to="">关于我们</router-link>
							</div>
						</li>

					</ul>
				</div>
			</div>
			<div class="ft3">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div class="cpt" style="color: #fff;">
								2024共达电声股份有限公司 版权所有 <a href="" style="color: #fff;">鲁ICP备06053283</a>
							</div>
						</div>
						<div class="col-12">
							<div class="cpt" style="color: #fff;">
								制作维护 | 山东公氏伟业信息技术有限公司
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<span class="g-goTop" @click="scroll"><img src="@/assets/images/icon-goTop.png" class="uc-icon28"
				alt="">返回顶部</span>
	</div>
</template>

<script>
	// document.addEventListener('scroll', function() {
	// 	console.log('页面正在滑动');
	// });

	// window.onscroll = function() {
	// 	var scrolledDistance = window.pageYOffset || document.documentElement.scrollTop;
	// 	console.log(scrolledDistance);
	// };

	export default {
		name: '',
		components: {},
		data() {
			return {}
		},
		mounted() {},
		methods: {
			scroll(pos) {
				window.scrollTo({
					top: 0,
					behavior: "smooth"
				});
			},
		},
	}
</script>

<style>
	.ft3 {
		border-top: 1px solid #006abc;
	}

	.ft2 .containerss .list h3 {
		font-size: 18px;
		color: #fff;
		font-weight: normal;
	}

	.ft2 .containerss .list .con a {
		font-size: 16px;
		color: #fff;
		font-weight: normal;
	}

	.logo_img {
		width: 264px;
	}

	.ft2 .containerss .contact .item {
		color: #fff;
	}

	.bg_blue {
		background-color: #004e8b;
	}
</style>