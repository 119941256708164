import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import "./assets/styles/main.css";


import ucHead from '@/components/ucHead'
Vue.component('ucHead', ucHead)
import ucFoot from '@/components/ucFoot'
Vue.component('ucFoot', ucFoot)
import ucBanner from '@/components/ucBanner'
Vue.component('ucBanner', ucBanner)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
